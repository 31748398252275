import Vue from 'vue'
import { Popover, Select,Steps,Step, Option, Input, 
  Button, InputNumber, RadioGroup, RadioButton, Tabs, 
  TabPane, Upload, Form, FormItem, Radio, Slider, Dialog, 
  MessageBox, Dropdown, DropdownMenu, DropdownItem, Avatar, 
  Progress, Card, Message, Checkbox, Switch, Tag, Loading, 
  Image,Collapse,CollapseItem,Badge,CheckboxGroup,Tooltip, 
  CheckboxButton
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-tiptap/lib/index.css';

import App from './App.vue'

import axios from 'axios'

import { enLanguage } from './lang/en.js'
import { zhHansLanguage } from './lang/zh-Hans.js'
import { zhHantLanguage } from './lang/zh-Hant.js'
import { dispatchEventStroage } from './utils'
import  store  from './store';

import router from './router/router.js'

Vue.prototype.enLanguage = enLanguage;
Vue.prototype.zhHansLanguage = zhHansLanguage;
Vue.prototype.zhHantLanguage = zhHantLanguage;

if (window.location.href.indexOf("cocorobo.cn") != -1) {
  Vue.prototype.language = zhHansLanguage;
} else if (window.location.href.indexOf("cocorobo.hk") != -1) {
  Vue.prototype.language = zhHantLanguage;
} else if (window.location.href.indexOf("cocorobo.com") != -1) {
  Vue.prototype.language = enLanguage;
} else {
  Vue.prototype.language = zhHansLanguage;
}
//在main.js引用drag.js的内容
import './drag'
import './moveDrag'

Vue.component(Popover.name, Popover)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Steps.name, Steps)
Vue.component(Step.name, Step)
Vue.component(Input.name, Input)
Vue.component(Button.name, Button)
Vue.component(InputNumber.name, InputNumber)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(RadioButton.name, RadioButton)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Upload.name, Upload)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Radio.name, Radio)
Vue.component(Slider.name, Slider)
Vue.component(Dialog.name, Dialog)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Avatar.name, Avatar)
Vue.component(Progress.name, Progress)
Vue.component(Card.name, Card)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Switch.name, Switch)
Vue.component(Tag.name, Tag)
Vue.component(Image.name, Image)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)
Vue.component(Badge.name, Badge)
Vue.component(CheckboxGroup.name,CheckboxGroup)
Vue.component(CheckboxButton.name,CheckboxButton)
Vue.component(Tooltip.name,Tooltip)
Vue.use(Loading.directive);
Vue.use(ElementTiptapPlugin, {
  lang: "zh", // see i18n
});
Vue.use(dispatchEventStroage);

//codemirror
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
Vue.use(VueCodemirror)

Vue.config.productionTip = false

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message

Vue.prototype.$ajax = axios
Vue.prototype.$bus = new Vue()

// import('vconsole').then(VConsole => {
//   new VConsole.default();
// });

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')