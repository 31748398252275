<template>
  <div>
    <!-- <div class="toolbar-item" @click="$_beautify_flow()" :title="languageData.beautifyAll">
      <beautify-flow size="18" />
    </div> -->
    <div class="toolbar-item" @click="$_fitView()" :title="languageData.global_view">
      <fit-view size="18" />
    </div>
    <div class="toolbar-item" @click="$_lookPre()" :title="languageData.look_last_card">
      <look-pre size="18" />
    </div>
    <div class="toolbar-item" @click="$_lookTheme()" :title="languageData.view_topic">
      <look-theme size="18" />
    </div>
    <div class="toolbar-item" @click="$_restore_background()" :title="languageData.restore_background">
      <restore-background size="18" />
    </div>
    <div class="toolbar-item" @click="$_selectionSelect()"
      :title="!selectionOpened ? languageData.enableBoxSelection : languageData.Uncheck">
      <area-select size="18" v-if="!selectionOpened" />
      <area-open size="18" v-else />
    </div>
    <!-- <div class="toolbar-item toolbar-color-picker">
      <el-popover
        placement="top-start"
        title="填充样式"
        width="220"
        trigger="click"
      >
        <sketch-picker :value="fillColor"  @input="$_changeFillColor"/>
        <color-fill size="24" slot="reference" />
      </el-popover>
    </div> -->
    <!-- <div class="toolbar-item">
      <color-text size="20" />
    </div>
    <div class="toolbar-item">
      <icon-font size="18" />
    </div>
    <div class="toolbar-item">
      <icon-blod size="18" />
    </div>
    <div class="toolbar-item">
      <icon-line size="18" />
    </div> -->
    <div class="toolbar-item" @click="$_zoomIn()" :title="languageData.enlarge">
      <zoom-in size="18" />
    </div>
    <div class="toolbar-item" @click="$_zoomOut()" :title="languageData.reduce">
      <zoom-out size="18" />
    </div>
    <div class="toolbar-item" :class="{ 'disabled': !undoAble }" @click="$_undo()" :title="languageData.revoke">
      <step-back size="18" />
    </div>
    <div class="toolbar-item" :class="{ 'disabled': !redoAble }" @click="$_redo()" :title="languageData.restore">
      <step-foward size="18" />
    </div>
    <div class="toolbar-item" @click="openMiniMap" :title="languageData.minimap">
      <mini-map size="18" />
    </div>
    <div class="toolbar-item" @click="importData" :title="languageData.import">
      <import size="18" />
    </div>
    <div class="toolbar-item" @click="$_saveGraph" :title="languageData.export">
      <export size="18" />
    </div>
    <!-- <div>
      <button @click="$_saveGraph">保存</button>
    </div> -->
    <div style="margin-left:5px;">
      <el-select v-model="lineType" size="mini" @change="$_changeLineType">
        <el-option v-for="item in lineOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
// import { Sketch } from 'vue-color'
// import ColorFill from './icon/ColorFill.vue'
// import ColorText from './icon/ColorText.vue'
// import IconFont from './icon/Font.vue'
// import IconBlod from './icon/Blod.vue'
// import IconLine from './icon/Line.vue'
import ZoomIn from './../icon/ZoomIn.vue'
import ZoomOut from './../icon/ZoomOut.vue'
import StepBack from './../icon/StepBack.vue'
import StepFoward from './../icon/StepFoward.vue'
import AreaSelect from './../icon/AreaSelect.vue'
import AreaOpen from './../icon/AreaOpen.vue'
import Import from './../icon/Import.vue'
import Export from './../icon/Export.vue'
import MiniMap from './../icon/MiniMap.vue'
// import BeautifyFlow from './../icon/BeautifyFlow.vue'
import RestoreBackground from './../icon/RestoreBackground.vue'
import FitView from './../icon/FitView.vue'
import LookPre from './../icon/LookPre.vue'
import LookTheme from './../icon/LookTheme.vue'
import $ from 'jquery'
import { sendUpdate, add_Operation } from '../../send.js'

export default {
  props: {
    lf: Object,
    activeEdges: Array,
    fillColor: {
      type: String,
      default: ''
    },
    linetype: {
      type: String,
      default: 'pro-line'
    },
    last_node_id:String,
    isSelectionSelect:Boolean
  },
  data() {
    return {
      selectionOpened: false,
      undoAble: false,
      redoAble: false,
      undos:[],
      colors: '#345678',
      lineType: 'pro-line',
      languageData: {},
      lineOptions: [
        {
          value: 'pro-line',
          label: window.location.href.indexOf("cocorobo.cn") != -1 ? this.zhHansLanguage.straightLine : (window.location.href.indexOf("cocorobo.hk") != -1 ? this.zhHantLanguage.straightLine : this.enLanguage.straightLine)
        },
        {
          value: 'pro-polyline',
          label: window.location.href.indexOf("cocorobo.cn") != -1 ? this.zhHansLanguage.polyline : (window.location.href.indexOf("cocorobo.hk") != -1 ? this.zhHantLanguage.polyline : this.enLanguage.polyline)
        },
        {
          value: 'pro-curved',
          label: window.location.href.indexOf("cocorobo.cn") != -1 ? this.zhHansLanguage.filletLine : (window.location.href.indexOf("cocorobo.hk") != -1 ? this.zhHantLanguage.filletLine : this.enLanguage.filletLine)
        },
        {
          value: 'pro-bezier',
          label: window.location.href.indexOf("cocorobo.cn") != -1 ? this.zhHansLanguage.curve : (window.location.href.indexOf("cocorobo.hk") != -1 ? this.zhHantLanguage.curve : this.enLanguage.curve)
        }
      ]
    }
  },
  watch: {
    linetype(newVal) {
      this.lineType = newVal
    },
    isSelectionSelect(newVal) {
      if(newVal) {
        if(this.selectionOpened) {
          this.$_selectionSelect()
        }
      }
    }
  },
  mounted() {
    this.$props.lf.on('history:change', ({ data: { undoAble, redoAble } }) => {
      this.$data.redoAble = redoAble
      this.$data.undoAble = undoAble
    })

    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    }
    else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    }else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    }
    else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    $_lookPre(){
      this.$props.lf.zoom(1)
      this.$emit("getTransformSize", 100)
      if(this.last_node_id){
        this.lf.focusOn({
          id:this.last_node_id
        })
      }
    },
    $_lookTheme(){
      this.$props.lf.zoom(1)
      this.$emit("getTransformSize", 100)
      let all_data = this.lf.getGraphData();
      let theme = all_data.nodes.filter(item=>{
        return item.type == "icon-theme";
      })
      this.lf.focusOn({
        id:theme[0].id
      })
    },
    $_fitView(){
      this.$props.lf.fitView(20, 20);
      // let lf = this.$props.lf;
      // const svgRootElement = lf.container.querySelector(".lf-canvas-overlay");
      // const base = lf.container.querySelector(".lf-base");

      // const svgRootElementRect = svgRootElement?.getBoundingClientRect();
      // const baseRect = base?.getBoundingClientRect();

      // const virtualRectWidth = baseRect?.width || 0;
      // const virtualRectHeight = baseRect?.height || 0;
      // // const virtualRectCenterPositionX = (baseRect?.left || 0) + (virtualRectWidth / 2);
      // // const virtualRectCenterPositionY = (baseRect?.top || 0) + (virtualRectHeight / 2);

      // const containerWidth = svgRootElementRect?.width || 0;
      // const containerHeight = svgRootElementRect?.height || 0;

      // const zoomRatioX = (virtualRectWidth + 20) / containerWidth;
      // const zoomRatioY = (virtualRectHeight + 20) / containerHeight;

      // // point
      // // const point = [containerWidth / 2, containerHeight / 2];
      // const point = [0, 0];

      // let zoomRatio = 0;
      // zoomRatio = 1 / Math.max(zoomRatioX, zoomRatioY);

      // lf.zoom(zoomRatio, point);
    },
    $_changeFillColor(val) {
      this.$emit('changeNodeFillColor', val.hex)
    },
    $_saveGraph() {
      this.$emit('exportData')
    },
    importData() {
      if (!(this.$props.lf.getEditConfig().isSilentMode)) {
        document.getElementById("JsonFile").click();
        // this.$emit('importData')
      }
    },
    openMiniMap() {
      if (this.$props.lf.extension.miniMap.isShow) {
        this.$props.lf.extension.miniMap.hide()
      }
      else {
        this.$props.lf.extension.miniMap.show()
      }
    },
    $_restore_background() {
      if (!(this.$props.lf.getEditConfig().isSilentMode)) {
        localStorage.setItem("background", '')
        let newData = this.$props.lf.getGraphData()
        newData.backgroundUrl = ''
        this.$parent.initLogicFlow(newData)
        // this.$props.lf.graphModel.graphDataToModel(newData)
        // this.$props.lf.options.grid = newData.backgroundUrl != "" ? false : true
        // this.$props.lf.options.background = {
        //   backgroundImage: 'url(' + newData.backgroundUrl + ')',
        //   backgroundRepeat: 'no-repeat',
        //   backgroundSize: 'cover',
        //   backgroundColor: '#F2F2F2'
        // };
        // this.$props.lf.graphModel.partial = false
        // this.$props.lf.render(this.$props.lf.getGraphData())
        // this.$props.lf.graphModel.partial = true
        sendUpdate([], [], "update");
        // add_Operation(newData, "updateNode")
      }
    },
    $_beautify_flow() {
      this.$props.lf.extension.dagre && this.$props.lf.extension.dagre.layout({
        nodesep: 20,
        ranksep: 20,
      });
    },
    $_zoomIn() {
      // this.$props.lf.zoom(true)
      var _this = this;
      _this.$nextTick(() => {
        var size = parseInt($(".scaleInfo").text().split("%")[0])
        if (size + 4 <= 400) {
          // $(".scaleInfo").text((size + 10).toString() + "%");
          _this.$props.lf.zoom((size + 4) / 100)
          _this.$emit("getTransformSize", (size + 4))
        }
      })
    },
    $_zoomOut() {
      // this.$props.lf.zoom(false)
      var _this = this;
      _this.$nextTick(() => {
        var size = parseInt($(".scaleInfo").text().split("%")[0])
        if (size - 4 >= 24) {
          // $(".scaleInfo").text((size - 10).toString() + "%");
          _this.$props.lf.zoom((size - 4) / 100)
          _this.$emit("getTransformSize", (size - 4))
        }
      })
    },
    $_undo() {
      if (!(this.$props.lf.getEditConfig().isSilentMode)) {
        if (this.$data.undoAble) {
          this.$props.lf.undo()
        }
      }
    },
    $_redo() {
      if (!(this.$props.lf.getEditConfig().isSilentMode)) {
        if (this.$data.redoAble) {
          this.$props.lf.redo()
        }
      }
    },
    $_selectionSelect() {
      if (!(this.$props.lf.getEditConfig().isSilentMode)) {
        this.selectionOpened = !this.selectionOpened
        if (this.selectionOpened) {
          this.lf.extension.selectionSelect.openSelectionSelect()
        } else {
          this.lf.extension.selectionSelect.closeSelectionSelect()
        }
        this.$emit("changeSelectionSelect",false)
      }
    },
    $_changeLineType(value) {
      if (!(this.$props.lf.getEditConfig().isSilentMode)) {
        const { lf, activeEdges } = this.$props
        const { graphModel } = lf
        lf.setDefaultEdgeType(value)
        this.$emit("changeLinetype", value)
        if (activeEdges && activeEdges.length > 0) {
          let newEdges = []
          activeEdges.forEach((edge, index) => {
            if (edge.type.indexOf("pro-red") == -1 && edge.type.indexOf("pro-green") == -1 && edge.type.indexOf("pro-dashed") == -1) {
              graphModel.changeEdgeType(edge.id, value)
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            else if (edge.type.indexOf("pro-red") != -1) {
              graphModel.changeEdgeType(edge.id, value.replace("pro", "pro-red"))
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            else if (edge.type.indexOf("pro-green") != -1) {
              graphModel.changeEdgeType(edge.id, value.replace("pro", "pro-green"))
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            else if (edge.type.indexOf("pro-dashed") != -1) {
              graphModel.changeEdgeType(edge.id, value.replace("pro", "pro-dashed"))
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            if (index == activeEdges.length - 1) {
              setTimeout(() => {
                sendUpdate([], newEdges, "update")
                let newData =  {
                  "nodes": [],
                  "edges": newEdges,
                  "backgroundUrl": localStorage.getItem("background")
                }
                add_Operation(newData, "updateEdge")
              }, 1000)
            }
          })
        }
        else {
          const data = lf.getGraphData()
          let newEdges = []
          data.edges.forEach((edge, index) => {
            if (edge.type.indexOf("pro-red") == -1 && edge.type.indexOf("pro-green") == -1 && edge.type.indexOf("pro-dashed") == -1) {
              graphModel.changeEdgeType(edge.id, value)
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            else if (edge.type.indexOf("pro-red") != -1) {
              graphModel.changeEdgeType(edge.id, value.replace("pro", "pro-red"))
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            else if (edge.type.indexOf("pro-green") != -1) {
              graphModel.changeEdgeType(edge.id, value.replace("pro", "pro-green"))
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            else if (edge.type.indexOf("pro-dashed") != -1) {
              graphModel.changeEdgeType(edge.id, value.replace("pro", "pro-dashed"))
              let newEdge = lf.getEdgeModelById(edge.id)
              newEdges.push(newEdge.getData())
            }
            if (index == data.edges.length - 1) {
              setTimeout(() => {
                sendUpdate([], newEdges, "update")
                let newData =  {
                  "nodes": [],
                  "edges": newEdges,
                  "backgroundUrl": localStorage.getItem("background")
                }
                add_Operation(newData, "updateEdge")
              }, 1000)
            }
          })
        }
      }
      else {
        this.lineType = 'pro-line'
      }
    }
  },
  components: {
    // ColorFill,
    // ColorText,
    // IconFont,
    // IconBlod,
    // IconLine,
    ZoomIn,
    ZoomOut,
    StepBack,
    StepFoward,
    AreaSelect,
    AreaOpen,
    MiniMap,
    Import,
    Export,
    RestoreBackground,
    FitView,
    LookPre,
    LookTheme
    // SketchPicker: Sketch
  }
}
</script>

<style>
.lf-mini-map {
  left: 83%;
  top: 7%;
}
</style>

<style scoped>
.toolbar-item {
  width: 18px;
  height: 18px;
  float: left;
  margin: 12px 4px;
  cursor: pointer;
}

.toolbar-color-picker {
  width: 24px;
  height: 24px;
  margin: 8px 4px;
}

.selection-active {
  background: #33a3dc;
}
</style>
