import ImageNode from './ImageNode'


// 图片-用户节点
class UserNode extends ImageNode.view {
  getImageHref () {
    const { properties } = this.props.model;
    return properties.url;
  }
}


export default {
  type: 'icon-ai-npc',
  view: UserNode,
  model: ImageNode.model
}