import { h } from '@logicflow/core'
import { GroupNode } from '@logicflow/extension';

class MyGroup extends GroupNode.view {
    getResizeShape() {
        const { x, y, width, height, properties } = this.props.model
        // console.log(properties)
        const style = this.props.model.getNodeStyle()
        const rectAttrs = {
            ...style,
            strokeWidth: 1,
            rx: 5,
            ry: 5,
            x: x - 1 / 2 * width,
            y: y - 1 / 2 * height,
            width,
            height,
        }
        const textAttrs = {
            x: x - 1 / 2 * width + 15,
            y: y - 1 / 2 * height + 20,
            // width: 50,
            // height: 50,
            stroke: "#2995FF"
        }
        // const aRectAttrs = {
        //     width: 18,
        //     height: 12,
        //     rx: 2,
        //     ry: 2,
        //     strokeWidth: 1,
        //     fill: "#F4F5F6",
        //     stroke: "#818281",
        //     cursor: "pointer",
        //     x: x - 1 / 2 * width + 15,
        //     y: y - 1 / 2 * height + 10,
        // }
        // const x1 = x - 1 / 2 * width + 24;
        // const y1 = y - 1 / 2 * height + 16;
        // const pointList = [
        //     [x1 - 1 / 2 * 8, y1 - 1 / 6 * 8],
        //     [x1 - 1 / 6 * 8, y1 - 1 / 6 * 8],
        //     [x1 - 1 / 6 * 8, y1 - 1 / 2 * 8],
        //     [x1 + 1 / 6 * 8, y1 - 1 / 2 * 8],
        //     [x1 + 1 / 6 * 8, y1 - 1 / 6 * 8],
        //     [x1 + 1 / 2 * 8, y1 - 1 / 6 * 8],
        //     [x1 + 1 / 2 * 8, y1 + 1 / 6 * 8],
        //     [x1 + 1 / 6 * 8, y1 + 1 / 6 * 8],
        //     [x1 + 1 / 6 * 8, y1 + 1 / 2 * 8],
        //     [x1 - 1 / 6 * 8, y1 + 1 / 2 * 8],
        //     [x1 - 1 / 6 * 8, y1 + 1 / 6 * 8],
        //     [x1 - 1 / 2 * 8, y1 + 1 / 6 * 8],
        // ]
        // const points = pointList.map(item => {
        //     return `${item[0]},${item[1]}`
        // })
        // const path = {
        //     ...style,
        //     x: x1,
        //     y: y1,
        //     width: 8,
        //     height: 8,
        //     fill: "#818281",
        //     stroke: "#818281",
        //     cursor: "pointer",
        //     strokeWidth: 1,
        //     strokeDasharray: "none",
        //     points: points.join(' '),
        // }
        // const gRectAttrs = {
        //     id: "fold_" + id
        // }
        return h('g', {}, [
            h('rect', { ...rectAttrs }),
            // h('g', { ...gRectAttrs }, [
            //     h('rect', { ...aRectAttrs }),
            //     h('polygon', { ...path },),
            // ]
            // ),
            h('text', { ...textAttrs }, properties.groupTitle)
        ]
        );
    }
}
class MyGroupModel extends GroupNode.model {
    initNodeData(data) {
        super.initNodeData(data);
        // console.log(data)
        this.text.editable = false; // 禁止节点文本编辑
        this.isRestrict = false;
        this.resizable = true;
        this.foldable = true;
        this.width = 500;
        this.height = 300;
        // this.isFolded = false
        this.foldedWidth = 50;
        this.foldedHeight = 50;
    }
    getNodeStyle() {
        const style = super.getNodeStyle();
        // style.stroke = '#AEAFAE';
        style.fill = '#EBEBEB';
        style.strokeDasharray = '3 3';
        style.strokeWidth = 1;
        return style;
    }
    // setAttributes() {
    //     this.isFolded = this.properties.isAlFolded ? this.properties.isAlFolded : false
    // }
}

export default {
    type: 'my-group',
    view: MyGroup,
    model: MyGroupModel
}
