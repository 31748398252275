import {
  HtmlResize
} from '@logicflow/extension';
import '../../../uml.css'
import img_pre from '../../../assets/title_pre.png'
import img_nor from '../../../assets/title_nor.png'
import { zhHansLanguage } from '../../../lang/zh-Hans.js'
import { zhHantLanguage } from '../../../lang/zh-Hant.js'
import { enLanguage } from '../../../lang/en.js'

class MapModel extends HtmlResize.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.text.editable = false; // 禁止节点文本编辑
    // 设置节点宽高和鼠标移动多少距离后开始缩放节点
    const width = 265;
    const height = 250;
    this.width = width;
    this.height = height;
    this.gridSize = 1;
    const unable_to_connect_with_oneself = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip43 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip43 : enLanguage.tip43),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
        message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_theme : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_theme : enLanguage.unable_to_connect_with_theme),
        validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
          console.log(sourceNode, sourceAnchor, targetAnchor)
          return targetNode.type != "icon-theme";
        },
    }
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_other : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_other : enLanguage.unable_to_connect_with_other),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id)
        let isConnect = false
        for(let i = 0;i < edges.length;i++) {
          if(edges[i].sourceNodeId == sourceNode.id && edges[i].targetNodeId == targetNode.id) {
            isConnect = true
            break
          }
        }
        return isConnect == false;
      },
    }
    this.sourceRules.push(unable_to_connect_with_other);
  }
}
class MapNode extends HtmlResize.view {
  contentTitle(eleDom, textTips, isShowDescribe) {
    const itemDom = document.createElement("div"); //增加提个DOM p标签，用于展示 自定义title
    itemDom.setAttribute('class', 'robotHeaderBar-Value'); //添加class命名，用于设置自定义title样式
    // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    const div = document.createElement("div");
    div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";

    const img = document.createElement("img");
    img.src = img_nor;
    div.appendChild(img);
    itemDom.appendChild(div);

    eleDom.parentNode.style = "overflow: visible;";
    // let itemDomShow = false; //用于判断是否添加了节点
    let itemClickDomShow = isShowDescribe;
    const p = document.createElement("p");
    div.title = textTips;

    if (itemClickDomShow) {
      p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
      p.style = "margin-left:10px;margin-right:10px;"
      itemDom.appendChild(p);
      img.src = img_pre;
      // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
      itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
      div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    }

    eleDom.appendChild(itemDom);
  }
  setHtml(rootEl) {
    const {
      id, properties
    } = this.props.model;
    var color = (properties.titleColor && properties.titleColor != '') ? properties.titleColor : '#F5A9A9'
    let cardTitle = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.camera_card : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.camera_card : enLanguage.camera_card)
    let tip61 = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip61 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip61 : enLanguage.tip61)
    const el = document.createElement('div');
    el.className = 'uml-wrapper';
    el.style = "border: 1px solid " + color + ';';
    var html = ``;
    if (properties.nodeSize && properties.nodeSize.height && parseInt(properties.nodeSize.height) <= 60 ||
      properties.nodeSize && properties.nodeSize.width && parseInt(properties.nodeSize.width) <= 60) {
      el.style = "border: 1px solid " + color + ';width:60px;height:60px;';
      if (properties.iconList && properties.iconList.length != 0) {
        html = `<div style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
          <img src="${properties.iconList[0].url}" />
        </div>
        `
      }
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);
    } else {
      html = `
      <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
      <div>
        <div class="uml-head" style="background-color:${color};">${cardTitle}</div>
        <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
        <div class="uml-body" style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
          <div style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <video style="width: 100%;height:100%" id="video_${id}"></video>
          </div>
        </div>
      </div>
      `;
      if (properties.src && properties.src != '') {
        // let video = document.getElementById("video_"+id);
        // if(video?.srcObject){
        //   JSON.parse(localStorage.mediaStreamTrack).getVideoTracks().forEach(
        //     track=>track.stop()
        //   )
        //   video.srcObject = null;
        // }
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div>
          <div class="uml-head" style="background-color:${color};">${cardTitle}</div>
          <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
              <img src="${properties.src}" style="width: 100%;height:100%" id="camera_${id}"></img>
            </div>
          </div>
        </div>
        `;
      } else {
        //默认文字
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div>
          <div class="uml-head" style="background-color:${color};">${cardTitle}</div>
          <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
              ${tip61}
            </div>
          </div>
        </div>
        `;
        //调用摄像头
        // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
        // navigator.getUserMedia({video:true,audio:false},success,error)
        // function success(stream) {
        //     var video = document.getElementById("video_"+id);
        //     localStorage.setItem("mediaStreamTrack",JSON.stringify(stream));
        //     video.srcObject = stream;
        //     video.play();
        // }

        // function error(err) {
        //     alert('video error: ' + err)
        // }
      }
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);
    }
  }
}

export default {
  type: 'icon-camera',
  view: MapNode,
  model: MapModel
}