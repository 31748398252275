// import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
import { HtmlResize } from '@logicflow/extension';
import '../../../uml.css'
// import $ from 'jquery'
import img_pre from '../../../assets/title_pre.png'
import img_nor from '../../../assets/title_nor.png'
import { zhHansLanguage } from '../../../lang/zh-Hans.js'
import { zhHantLanguage } from '../../../lang/zh-Hant.js'
import { enLanguage } from '../../../lang/en.js'

class WhiteBoardModel extends HtmlResize.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.text.editable = false; // 禁止节点文本编辑
    // 设置节点宽高和鼠标移动多少距离后开始缩放节点
    const width = 265;
    const height = 250;
    this.width = width;
    this.height = height;
    this.gridSize = 1;

    const unable_to_connect_with_oneself = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip43 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip43 : enLanguage.tip43),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_theme : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_theme : enLanguage.unable_to_connect_with_theme),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor)
        return targetNode.type != "icon-theme";
      },
    }
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_other : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_other : enLanguage.unable_to_connect_with_other),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id)
        let isConnect = false
        for(let i = 0;i < edges.length;i++) {
          if(edges[i].sourceNodeId == sourceNode.id && edges[i].targetNodeId == targetNode.id) {
            isConnect = true
            break
          }
      }
        return isConnect == false;
      },
    }
    this.sourceRules.push(unable_to_connect_with_other);

    // this.minWidth = 60;
    // this.minHeight = 60;
    // this.gridSize = 1;
    // 设置锚点
    // this.anchorsOffset = [
    //   [width / 2, 0],
    //   [0, height / 2],
    //   [-width / 2, 0],
    //   [0, -height / 2],
    // ]
  }
  // getResizeOutlineStyle(){
  //   const style = super.getResizeOutlineStyle();
  //   style.stroke = "#007BFF";
  //   style.strokeWidth = 2
  //   style.strokeDasharray = "3,3"
  //   style.fill = "transparent"
  //   return style;
  // }
}
class WhiteBoardNode extends HtmlResize.view {
  contentTitle(eleDom, textTips, lf, isShowDescribe) {
    const itemDom = document.createElement("div"); //增加提个DOM p标签，用于展示 自定义title
    itemDom.setAttribute('class', 'robotHeaderBar-Value'); //添加class命名，用于设置自定义title样式
    // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    const div = document.createElement("div");
    div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";

    const img = document.createElement("img");
    img.src = img_nor;
    div.appendChild(img);
    itemDom.appendChild(div);

    eleDom.parentNode.style = "overflow: visible;";
    // let itemDomShow = false; //用于判断是否添加了节点
    let itemClickDomShow = isShowDescribe;
    const p = document.createElement("p");
    div.title = textTips;

    if (itemClickDomShow) {
      p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
      p.style = "margin-left:10px;margin-right:10px;"
      itemDom.appendChild(p);
      img.src = img_pre;
      // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
      itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
      div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    }

    eleDom.appendChild(itemDom);
  }
  setHtml(rootEl) {
    const { id, properties } = this.props.model;
    var color = (properties.titleColor && properties.titleColor != '') ? properties.titleColor : '#5493C0'
    let cardTitle = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.whiteboard_card : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.whiteboard_card : enLanguage.whiteboard_card)
    const el = document.createElement('div');
    el.className = 'uml-wrapper';
    // el.title = properties.describe ? properties.describe : ''
    // el.style = "border: 1px solid " + color + ';';
    var html = ``;
    if (properties.nodeSize && properties.nodeSize.height && parseInt(properties.nodeSize.height) <= 60
      || properties.nodeSize && properties.nodeSize.width && parseInt(properties.nodeSize.width) <= 60) {
      el.style = "border: 1px solid " + color + ';width:60px;height:60px;';
      if (properties.iconList && properties.iconList.length != 0) {
        html = `<div style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
          <img src="${properties.iconList[0].url}" />
        </div>
        `
      }
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);
    }
    else {
      html = `
      <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
      <div>
        <div class="uml-head" style="background-color:${color};">${properties.whiteboardTitle ? properties.whiteboardTitle : cardTitle}</div>
        <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
        <div class="uml-body" style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
          <div style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <p>${ window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip76 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip76 : enLanguage.tip76) }</p>
          </div>
        </div>
      </div>
      `;
      if (properties.whiteboardContent && properties.whiteboardContent != '') {
        // console.log(properties.imageList)
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div style="width: 100%;height: 100%;">
          <div class="uml-head" style="background-color:${color};">${properties.whiteboardTitle ? properties.whiteboardTitle : cardTitle}</div>
          <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 186 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 186 + 'px')};padding: 0;display: flex;flex-direction: row;justify-content: center;align-items: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div style="width: 100%; height: 100%;display:flex;flex-direction: row;justify-content: center;">
              <img style="width: 265px;height:186px" src="${properties.whiteboardContent}" />
            </div>
          </div>
        </div>
        `;
      }
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);

      if (properties.describe) {
        const textTips = properties.describe ? properties.describe : '';
        //添加自定义属性 target-title
        //itemContainer表示，需要在哪个DOM下增加title（自定义属性）
        el.setAttribute("target-title", textTips);
        //判断这个DOM节点是否包含这个自定义属性，
        if (el.getAttributeNode("target-title")) {
          //有的话直接调用函数。（用于多个相同DOM命名，统一添加，）
          const textTips = el.getAttribute('target-title');
          //如果用的地方比较少，不需要多个添加，可以直接调用函数
          //itemContainer 表示，需要在哪个DOM下增加title
          //textTips  需要展示的title文本
          this.contentTitle(el, textTips, this.props, properties.isShowDescribe);
        }
      }
    }
  }
}

export default {
  type: 'icon-whiteboard',
  view: WhiteBoardNode,
  model: WhiteBoardModel
}
